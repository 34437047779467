import React, { useEffect, useState } from "react";
import {
  Shell,
  Carousel,
  Card,
  Text,
  Button,
  Scheduler,
  LiveIcon,
  RightArrowIcon,
  Dropdown,
  Video,
} from "@StixNext/mojito-ui";
import { Column, Grid, Row } from "@StixNext/mojito-ui";
import { connect } from "react-redux";
import moment from "moment";
import * as actions from "../actions/Programmes";
import * as Images from "./common/watch-free";
import Bravissimo from "../assets/images/bravissimo-thumbnail.jpg";
import livestreamdot from "../assets/images/icons/livestream_dot.svg";
import play_oval from "../assets/images/play_oval.svg";
import play_path from "../assets/images/play_path.svg";

const LiveStreamNew = (props) => {
  const {
    programmesList = [],
    fetchProgrammes,
    history,
    dateSelected,
    setDateSelected,
  } = props;
  const handleClick = (eventId) => {
    history.push(`/livevideo/${eventId}`);
  };
  return (
    <Shell.Body className={"css-livestreamnow"}>
      <Grid>
        <Row>
          <Column>
            <Text as="h5" className="concert">
              NXT FINALE CONCERT
            </Text>
          </Column>
        </Row>
      </Grid>
      <Grid>
        <Row>
          <Column
            className="img_inline"
            onClick={() => handleClick("FP1")}
            style={{
              backgroundImage: `url(${Bravissimo})`,
            }}
          >
            <div className="play_img_div">
              {/* <img src={Bravissimo} className="img_brav" /> */}
              <img src={play_path} className="img_path" />
              <img src={play_oval} className="img_oval" />
            </div>
          </Column>
        </Row>
      </Grid>
      <Grid>
        <Row>
          <div className={"justify"}>
            <Column>
              <Text as="h6" className="synopsistext">
                Synopsis
              </Text>
              <Text as="p">
                {
                  "Singapore Street Festival (SSF) celebrates its 20th Anniversary with a new theme: NXT – a virtual music event. SSF is going digital this year! Also, we are back to venues where SSF first started 20 years ago, at the Somerset Belt."
                }
                <br />
                <br />
                {
                  "SSF is a non-profit social and community project that was founded in 2002. This yearly festival has been in partnership with statutory agencies and strong supporters to provide the youth in Singapore with a platform to showcase their talents, skills and capabilities in the performing arts and visual arts."
                }
                <br />
                <br />
                {
                  "SSF 2021 supporting partners are the Academy of Rock, Xctuality, Sistic.Com, Music & Drama Company, CrossRatio and Moulmein-Cairnhill Youth Network. Passion Card is the Privileged Card."
                }
                <br />
                <br />
                {
                  "In this 20th Anniversary celebration, SSF in NXT – a virtual music event has commissioned 3 youth composers and garnered a panel of 9 professional mentors to help nurture youths in the area of music.  "
                }
                <br />
                <br />
                {
                  "SSF believes that youth aren’t just the leaders of tomorrow, they are also youth leaders of today. YouthHarmony, a new youth organization is SSF’s adopted youth in training to plan and execute the NXT – A Virtual Music Event. We will also be in collaboration with youths from the Moulmein-Cairnhill Youth Networks during the planning and execution of this project. This new generation of youths are experts in handling creative tools and will be of a great influence to their participating peers."
                }
              </Text>
            </Column>
          </div>
        </Row>
      </Grid>
    </Shell.Body>
  );
};
const mapStateToProps = (state) => ({
  programmesList: state.Programmes.programmesList,
  dateSelected: state.Programmes.dateSelected,
  loginDetails: state.Login,
});

const mapDispatchToProps = (dispatch) => ({
  fetchProgrammes: () => dispatch(actions.fetchProgrammes()),
  setDateSelected: (date) => dispatch(actions.setDateSelected(date)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LiveStreamNew);
