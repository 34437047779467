import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import {
  Text,
  Button,
  BouncerContextHook,
  SisticLivePlayerAlt,
  SisticLivePlayer,
} from "@StixNext/mojito-ui";
import ReactHtmlParser from "react-html-parser";
import Iframe from "react-iframe";
import Moment from "react-moment";
import livestreamdot from "../assets/images/icons/livestream_dot.svg";
import Book from "../assets/images/book.png";
import BookStore from "../assets/images/bookstore.jpg";
import Donation from "../assets/images/donation.png";
import SecondScreen from "../assets/images/second_screen.png";
import Arrow from "../assets/images/leftarrow.png";
import ChatIco from "../assets/images/icons/chat_icon_black.svg";
import QAIco from "../assets/images/icons/qa_icon_black.svg";
import PollIco from "../assets/images/icons/poll_icon_black.svg";
import AddonIco from "../assets/images/icons/star_icon_black.svg";
import DisablePopup from "../assets/images/disable_popup.png";
import { TransactionStatus } from "../components/BuyProductAndTransaction";
import Keycloak from "keycloak-js";
import * as actions from "../actions/Login";
import * as transActions from "../actions/Transaction";
import * as prodActions from "../actions/Products";
import * as progrmmeActions from "../actions/Programmes";
import history from "../stores/history";
import Chat from "@StixNext/mojito-ui/dist/Chat/Chat";
// import VideoOverlay from "../assets/images/video_overlay.jpg";
import LiveChat from "./LiveChat";
import LiveReaction from "./LiveReaction";
import Bravissimo from "../assets/images/bravissimo-thumbnail.jpg";

function VideoDetail(props) {
  const { history, location = {} } = props;
  const loginContext = BouncerContextHook();
  const [ChatOrQA, setChatOrQA] = useState("addon");
  const [play, setPlay] = useState(false);
  const [end, setEnd] = useState(false);
  const [start, setStart] = useState(false);
  const [legalAge, setLegalAge] = useState(false);
  const [hideAgeOverlay, setHideAgeOverlay] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [fullscreenEnable, setFullScreenEnable] = useState(false);
  const [addOnSection, setAddOnSection] = useState([]);
  const [addonStart, setAddonStart] = useState(false);
  const [addonStop, setAddonStop] = useState(false);

  let interval = null;
  let addOnArray = [];
  function myFunction(startDateTime, endDateTime) {
    interval = setInterval(function () {
      var serverDate = moment(new Date(moment.utc().format()));
      var indiaDate = moment(new Date(moment.utc(startDateTime).format()));
      setStart(true);

      if (indiaDate.diff(serverDate, "seconds") < 1) {
        if (!play) {
          setPlay(true);
          setStart(false);
        }
        clearInterval(interval);
      }
    }, 1000);
  }
  // let onClose = () => {
  //   props.changeTransactionValues("productPrice", 0);
  //   props.changeTransactionValues("showDropinComponent", false);
  //   props.changeTransactionValues("transactionStatus", 1);
  //   props.changeTransactionValues("checkconsent", false);
  //   props.changeTransactionValues("isOpen", false);
  //   localStorage.setItem("buttonClicked", false);
  // };

  let getDegitalPass = async (e) => {
    localStorage.setItem("buttonClicked", true);
    setTimeout(() => {
      if (!props.loginDetails.authenticated) {
        loginContext.loginKeycloak();
      } else {
        props.fetchTransaction(
          props.loginDetails.user,
          localStorage.getItem("buttonClicked")
        );
      }
    }, 1000);
  };
  useEffect(() => {
    // setPlay(true);
    let progammeId = props.match.params.id;
    props.fetchProgrammeDetail(progammeId);
    return () => {
      clearInterval(interval);
      props.changeProgrammesValues("programme", []);
    };
  }, []);
  useEffect(() => {
    if (props.programmeDetails.programme.length > 0) {
      myFunction(
        props.programmeDetails.programme[0].startDateTimeUTC,
        props.programmeDetails.programme[0].endDateTimeUTC
      );
      if (
        props.programmeDetails.programme[0].ChatFlag &&
        props.programmeDetails.programme[0].QAFlag
      ) {
        setChatOrQA("chat");
      } else if (props.programmeDetails.programme[0].ChatFlag) {
        setChatOrQA("chat");
      } else if (props.programmeDetails.programme[0].QAFlag) {
        setChatOrQA("QA");
      }
    }
  }, [props.programmeDetails.programme]);

  useEffect(() => {
    if (localStorage.getItem("goToFinale") === "true") {
      if (props.loginDetails.authenticated) {
        localStorage.setItem("goToFinale", "");
        history.push("/livevideo/FP1");
      }
    }
  }, [props.loginDetails.authenticated]);

  return (
    <>
      {props.programmeDetails.programme.length > 0 &&
        props.programmeDetails.programme.map((value, key) => {
          let showLive = new Date() > new Date(value.startDateTime);
          return (
            <div className="row vodContainer">
              <div className="container">
                <Link
                  to={
                    localStorage.getItem("prevPath")
                      ? localStorage.getItem("prevPath")
                      : "/"
                  }
                  className="title"
                >
                  <img src={Arrow} alt="icon" />
                  <Text as="captionblock" className="titletext">
                    <div
                      style={{
                        fontSize: "14px",
                        display: "flex",
                        flexFlow: "row",
                        alignItems: "center",
                        columnGap: "6px",
                      }}
                    >
                      {value.id === "FP1" ? (
                        "Video On Demand"
                      ) : (
                        <>
                          Live stream
                          <img src={livestreamdot} alt="icon" />
                        </>
                      )}
                    </div>
                    {value.title}
                    <br />
                    <div style={{ fontSize: "13px", fontWeight: "100" }}>
                      <p>
                        {" "}
                        {value.timing}
                        {/* <Moment format="DD MMM YYYY">
                        {value.startDateTime}
                      </Moment>{" "}
                      <Moment format="LT">{value.startDateTime}</Moment> */}
                        {" @ "}
                        <span style={{ fontWeight: "bold" }}> NXT </span>
                      </p>
                    </div>
                  </Text>
                </Link>
                <div className="wholediv">
                  <div className="videodiv disablechat">
                    {!showLive ? (
                      <div className="show-live">
                        <iframe
                          src={"https://ssf20-nxt.xctuality.com/"}
                          width="100%"
                          height="100%"
                          frameborder="0"
                          scrolling="no"
                          allow="autoplay"
                          autoplay="true"
                          allowFullScreen="true"
                          webkitallowfullscreen="true"
                          mozallowfullscreen="true"
                          oallowfullscreen="true"
                          msallowfullscreen="true"
                        ></iframe>
                      </div>
                    ) : (
                      ""
                    )}

                    {!loginContext.sessionRestrictor &&
                      props.loginDetails.authenticated &&
                      showLive &&
                      (value.id == "FP1" || value.id == "FP3" ? (
                        <SisticLivePlayerAlt
                          playerItems={{
                            src: Bravissimo, // value.VideoAssetURL,
                            reactionContent: (
                              <LiveReaction
                                highlight={value.id == "FP3"}
                                session={props.match.params.id}
                              />
                            ),
                            chatContent: (
                              <LiveChat
                                highlight={value.id == "FP3"}
                                session={props.match.params.id}
                              />
                            ),
                          }}
                        />
                      ) : value.id == "FP5" ? (
                        <iframe
                          src={value.VideoAssetURL}
                          width="100%"
                          height="100%"
                          frameborder="0"
                          scrolling="no"
                          allow="autoplay"
                          autoplay="true"
                          allowFullScreen="true"
                          webkitallowfullscreen="true"
                          mozallowfullscreen="true"
                          oallowfullscreen="true"
                          msallowfullscreen="true"
                        ></iframe>
                      ) : (
                        <SisticLivePlayer
                          playerItems={{
                            player: false,
                            playing: true,
                            muted: true,
                            start: start,
                            startDateTime: value.startDateTime,
                            endDateTime: value.endDateTime,
                            authenticated: props.loginDetails.authenticated,
                            hideBuyButton:
                              props.transactionDetails.hideBuyButton,
                            url: value.VideoAssetURL,
                            type: "mp4",
                            trackingId: `${process.env.REACT_APP_GID}`,
                            disableDuration: true,
                            disableSeekBar: true,
                            // poster: "//vjs.zencdn.net/v/oceans.png",
                            enableReaction: true,
                            reactionContent: (
                              <LiveReaction session={props.match.params.id} />
                            ),
                            enableChat: value.ChatFlag ? value.ChatFlag : false,
                            chatContent: (
                              <LiveChat session={props.match.params.id} />
                            ),
                          }}
                        />
                      ))}
                  </div>
                  {props.loginDetails.authenticated === null ||
                  props.loginDetails.authenticated === false ||
                  props.transactionDetails.hideBuyButton === false ? (
                    // !value.ChatFlag && !value.QAFlag ? (
                    <div className="mobchatalt"></div>
                  ) : (
                    ""
                  )}
                  {loginContext.sessionRestrictor ||
                  props.loginDetails.authenticated === null ||
                  props.loginDetails.authenticated === false ? (
                    //props.transactionDetails.hideBuyButton === false ? (
                    <div className="wrap_div">
                      <Text as="h4" className="wrap_title">
                        {"This video is only available upon login "}
                      </Text>

                      {loginContext.sessionRestrictor ||
                      props.loginDetails.authenticated === null ||
                      props.loginDetails.authenticated === false ? (
                        <div>
                          <Text as="h6" className="wrap_pass">
                            {"Login here"}
                          </Text>
                          <Button
                            primary
                            className="video_btn"
                            onClick={(e) => {
                              localStorage.setItem("goToFinale", "true");
                              setTimeout(() => {
                                loginContext.loginKeycloak();
                              }, 1000);
                            }}
                          >
                            Click here to login
                          </Button>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="synopiscontainer to-mob">
                <div className="synopisdiv">
                  <div className="synopissection">
                    <div>
                      <Text as="h6" className="synopsis">
                        21 Jan 2022, 8:00pm till 9:45pm (GMT +8)
                      </Text>

                      <Text as="h6" className="synopsis">
                        INTRODUCTION
                      </Text>
                      <Text as="p">
                        {value.writeUp &&
                          ReactHtmlParser(
                            value.writeUp.replace(/(?:\r\n|\r|\n)/g, "<br />")
                          )}
                      </Text>
                      <div className="bookwebsection">
                        <div className="booksection">
                          {value.donationLink && (
                            <a
                              href={value.donationLink}
                              target="_blank"
                              style={{
                                display: "flex",
                                textDecoration: "none",
                              }}
                            >
                              <img
                                src={Donation}
                                alt="icon"
                                style={{
                                  width: "150px",
                                  height: "150px",
                                  borderRadius: "50%",
                                }}
                              />
                              <Text as="p" className="booktext">
                                Click here to donate <br />
                                to our giving.sg
                              </Text>
                            </a>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bookcontainer">
                <div className="bookdiv">
                  <div className="bookmobsection">
                    {value.bookstoreLink && (
                      <a
                        href={`https://${value.bookstoreLink}`}
                        target="_blank"
                        style={{
                          display: "flex",
                          paddingLeft: "15px",
                          paddingBottom: "32px",
                          textDecoration: "none",
                        }}
                      >
                        <img
                          src={BookStore}
                          alt="icon"
                          style={{ width: "110px", height: "170px" }}
                        />
                        <Text as="p" className="booktext">
                          Visit swfbooks.com
                        </Text>
                      </a>
                    )}
                    {value.donationLink && (
                      <div
                        style={{
                          display: "flex",
                          paddingLeft: "15px",
                          paddingBottom: "32px",
                        }}
                      >
                        <img
                          src={Donation}
                          alt="icon"
                          style={{
                            width: "150px",
                            height: "150px",
                            borderRadius: "50%",
                          }}
                        />
                        <Text as="p" className="booktext">
                          Click here to donate <br />
                          to our giving.sg
                        </Text>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      {/* <TransactionStatus
        isOpen={props.transactionDetails.isOpen}
        onClose={onClose}
        transactionSuccessFlag={props.transactionDetails.transactionSuccessFlag}
        transactionData={props.transactionDetails.transactionData}
        username={props.loginDetails.keycloak}
      /> */}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    loginDetails: state.Login,
    transactionDetails: state.Transaction,
    programmeDetails: state.Programmes,
  };
};

const mapDispatchToProps = (dispatch) => ({
  changeLoginValues: (f, e) => dispatch(actions.changeLoginValues(f, e)),
  getAuthToken: () => dispatch(actions.getAuthToken()),
  fetchTransaction: (data, buttonClicked) =>
    dispatch(transActions.fetchTransaction(data, buttonClicked)),
  changeTransactionValues: (f, e) =>
    dispatch(transActions.changeTransactionValues(f, e)),
  changeProgrammesValues: (f, e) =>
    dispatch(progrmmeActions.changeProgrammesValues(f, e)),
  checkoutTransaction: (cardPayload, user) =>
    dispatch(transActions.checkoutTransaction(cardPayload, user)),
  fetchProducts: () => dispatch(prodActions.fetchProducts()),
  fetchProgrammeDetail: (id) =>
    dispatch(progrmmeActions.fetchProgrammeDetail(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(VideoDetail);
